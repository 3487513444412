import React from 'react';
import './FormButton.scss';

interface FormButton {
    buttonId: string;
    buttonType: 'submit' | 'button';
    buttonText: string;
    classNames?: String;
    disabled: boolean;
    onClick?: () => void;
}
const FormButton: React.FC<FormButton> = ({
    buttonId,
    buttonType,
    buttonText,
    classNames = '',
    onClick,
    disabled = false,
}) => (
    <button
        className={`form-button ${classNames}`}
        disabled={disabled}
        type={buttonType}
        id={buttonId}
        onClick={onClick}
    >
        {buttonText}
    </button>
);

export default FormButton;
