import React, { Component } from 'react';

import { onlyLetterValdation, emailValidation, isOverAge, setTrackingEvent, doubleInt, isOfAge } from 'utils';
import FormButton from 'components/FormButton/FormButton';
import InputField from 'components/InputField/InputField';
import Tooltip from 'components/Tooltip/Tooltip';
import FormError from 'components/FormError/FormError';
import UserService from 'utils/userService';
import { monthNames, DOB_INFO_TEXT, USER_ERROR_EXISTS, Screens, INVALID_EMAIL_ERROR } from 'containers/constants';

import './Register.scss';

interface IRegisterProps {
    handleScreen: Function;
    handleUserUpdate: Function;
}

export default class Register extends Component<IRegisterProps> {
    state = {
        isLoading: false,
        registered: false,
        given_name: '',
        family_name: '',
        dob_day: '',
        dob_month: '',
        dob_year: '',
        email: '',
        password: '',
        terms: false,
        calendarDate: new Date(),
        errors: {
            email: '',
            dob: '',
            password: '',
            given_name: '',
            family_name: '',
        },
        responseError: '',
    };

    componentDidMount(): void {
        setTrackingEvent('regStart', 'registration', { registrationStep: 'started' });
    }

    componentWillUnmount(): void {
        if (!this.state.registered) setTrackingEvent('regAbandon', 'registration', { registrationStep: 'abandoned' });
    }

    handleSignin = () => {
        setTrackingEvent('regAbandon', 'registration', { registrationStep: 'abandoned' });
        this.props.handleScreen(Screens.SIGNIN);
    };

    prefixZero = (e: React.ChangeEvent<HTMLInputElement>): React.ChangeEvent<HTMLInputElement> => {
        const value = e.target.value;
        e.target.value = value.length === 1 && value !== '0' ? `0${value}` : value;
        e.target.value = e.target.value.length > 2 ? parseInt(e.target.value).toString() : e.target.value;
        return e;
    };

    validateFormFields = async (event: any) => {
        event.preventDefault && event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name) {
            case 'given_name':
                errors.given_name = value && !onlyLetterValdation(value) ? 'Must be letters only' : '';
                errors.given_name =
                    value && value.length > 50 ? 'Can not be longer than 50 charactes' : errors.given_name;
                break;
            case 'family_name':
                errors.family_name = value && !onlyLetterValdation(value) ? 'Must be letters only' : '';
                errors.family_name =
                    value && value.length > 50 ? 'Can not be longer than 50 charactes' : errors.family_name;
                break;
            case 'email':
                errors.email = value && !emailValidation(value) ? 'Email is not valid' : '';

                try {
                    const userService = new UserService();
                    const result = await userService.emailValidation(value);
                    const emailParts = Object.values(result);
                    errors.email = !emailParts.every((value) => value) ? 'Please enter a valid email' : errors.email;
                } catch (error) {
                    //
                }
                break;
            case 'password':
                errors.password = value && value.length < 6 ? 'Password must be 6 characters long' : '';
                break;
            case 'dob_day':
            case 'dob_month':
            case 'dob_year':
                const { dob_day, dob_month, dob_year } = this.state;
                // check date
                const dateString = `${dob_month}/${dob_day}/${dob_year}`;
                const selectedDob = new Date(dateString);
                if (dob_day === '' || dob_month === '' || dob_year === '' || dob_year.length < 4) {
                    errors.dob = 'This is a required field';
                } else if (selectedDob.toString() === 'Invalid Date' || parseInt(dob_year) < 1900) {
                    errors.dob = 'Please enter a valid date of birth';
                } else if(parseInt(dob_month) < selectedDob.getMonth() + 1) {
                    // check for intercalary year
                    errors.dob = 'Please enter a valid date of birth';
                } 
                else if (!isOfAge(dateString, 18)) {
                    errors.dob = 'You must be over 18 to create a My5 account';
                } else {
                    errors.dob = '';
                }
                break;
            default:
                break;
        }
        this.setState({ errors, [name]: value });
    };

    validateForm = () => {
        const fieldsAreValid = () => {
            let valid = true;
            Object.values(this.state.errors).forEach(
                // if we have an error string set valid to false
                (val) => val.length > 0 && (valid = false),
            );
            return valid;
        };
        return (
            !this.state.isLoading &&
            this.state.terms &&
            this.state.email.length > 0 &&
            this.state.dob_day.length > 0 &&
            this.state.dob_month.length > 0 &&
            this.state.dob_year.length > 0 &&
            this.state.password.length > 0 &&
            this.state.given_name.length > 0 &&
            this.state.family_name.length > 0 &&
            fieldsAreValid()
        );
    };

    handleChange = (event: any, callback?: Function) => {
        this.setState({
            [event.target.id]: event.target.value,
        }, () => callback && callback(event));
    };

    handleSubmit = async (event: any) => {
        event.preventDefault();

        const { given_name, family_name, email, password, errors, dob_day, dob_month, dob_year } = this.state;

        setTrackingEvent('regSubmit', 'registration', { registrationStep: 'submitted' });

        if (dob_day === '' || dob_month === '' || dob_year === '') {
            return this.setState({ errors: { ...errors, dob: 'The Date of Birth is not valid!' } });
        }

        if (email) {
            this.setState({ isLoading: true }, async () => {
                const dob = `${doubleInt(dob_day)}/${doubleInt(dob_month)}/${dob_year}`;
                try {
                    const userService = new UserService();
                    const result = await userService.signUp(
                        email.toLowerCase().trim(),
                        password,
                        given_name.trim(),
                        family_name.trim(),
                        dob,
                    );

                    await this.props.handleUserUpdate();

                    if (result && result.userConfirmed) {
                        await userService.signIn(email.toLowerCase(), password);
                        const userInfo = await userService.getUserInfo();

                        await this.props.handleUserUpdate();
                        this.setState({ registered: true }, () => {
                            setTrackingEvent('regComplete', 'registration', {
                                registrationStep: 'completed',
                                profileID: userInfo.userId,
                                regUsrAgeBracket: userInfo.ageBracket,
                                loggedInStatus: 'logged in',
                                ageBracket: userInfo.ageBracket,
                            });

                            this.props.handleScreen(Screens.PIN_SCREEN);
                        });
                        this.setState({ isLoading: false });
                    } else {
                        setTrackingEvent('regError', 'registration', {
                            errorCode: `The response of Cognito SignUp is null`,
                        });
                        this.setState({ responseError: result.message, isLoading: false });
                    }
                } catch (e) {
                    const { code, message } = e as { code: string; message: string };
                    const responseError = code === 'UsernameExistsException' ? USER_ERROR_EXISTS : message;
                    setTrackingEvent('regError', 'registration', { errorCode: `${code} (${code})` });
                    this.setState({ responseError, isLoading: false });
                }
            });
        } else {
            setTrackingEvent('regError', 'registration', { errorCode: `Invalid email address` });
            this.setState({ responseError: INVALID_EMAIL_ERROR });
        }
    };

    toggleTerms = () => {
        this.setState({ terms: !this.state.terms });
    };

    render() {
        const {
            given_name,
            family_name,
            email,
            dob_day,
            dob_month,
            dob_year,
            password,
            terms,
            errors,
            responseError,
            isLoading,
        } = this.state;

        return (
            <form onSubmit={this.handleSubmit} className='register'>
                <div className='register-fields'>
                    <div className='column-2'>
                        <InputField
                            inputType='text'
                            inputValue={given_name}
                            labelText='First name'
                            inputId='given_name'
                            handleChange={this.handleChange}
                            blurValidation={this.validateFormFields}
                            error={errors.given_name && errors.given_name}
                        />
                        <InputField
                            inputType='text'
                            inputValue={family_name}
                            labelText='Last name'
                            inputId='family_name'
                            handleChange={this.handleChange}
                            blurValidation={this.validateFormFields}
                            error={errors.family_name && errors.family_name}
                        />
                    </div>
                    <div className='form-input-group'>
                        <div className='form-input-group double-label'>
                            <label className='input-label'>Date of Birth</label>
                        </div>
                        <div className='row date'>
                            <InputField
                                inputType='number'
                                inputValue={dob_day}
                                labelText='Day'
                                placeholder='DD'
                                min={1}
                                max={31}
                                inputId='dob_day'
                                handleChange={(e: any) => this.handleChange(this.prefixZero(e), this.validateFormFields)}
                                blurValidation={this.validateFormFields}
                                classNames='no-padding'
                            />
                            <InputField
                                inputType='number'
                                inputValue={dob_month}
                                labelText='Month'
                                placeholder='MM'
                                min={1}
                                max={12}
                                inputId='dob_month'
                                handleChange={(e: any) => this.handleChange(this.prefixZero(e), this.validateFormFields)}
                                blurValidation={this.validateFormFields}
                                classNames='no-padding'
                            />
                            <InputField
                                inputType='number'
                                inputValue={dob_year}
                                labelText='Year'
                                placeholder='YYYY'
                                min={1900}
                                inputId='dob_year'
                                handleChange={(e: any) => this.handleChange(e, this.validateFormFields)}
                                blurValidation={this.validateFormFields}
                                classNames='no-padding'
                            />
                        </div>
                        <Tooltip header='Date of Birth' tooltipText={DOB_INFO_TEXT} />
                        {errors.dob && <FormError error={errors.dob} />}
                    </div>
                    <InputField
                        inputType='email'
                        inputValue={email}
                        labelText='Email address'
                        inputId='email'
                        handleChange={this.handleChange}
                        blurValidation={this.validateFormFields}
                        error={errors.email && errors.email}
                    />
                    <InputField
                        inputType='password'
                        inputValue={password}
                        labelText='Password'
                        inputId='password'
                        handleChange={this.handleChange}
                        showHide
                        blurValidation={this.validateFormFields}
                        error={errors.password && errors.password}
                    />
                </div>
                <div className='extra-content terms'>
                    <div className={`terms-check ${terms ? 'accepted' : ''}`} onClick={this.toggleTerms}></div>
                    <span className='terms-confirm-text' onClick={this.toggleTerms}>
                        I confirm I have read and accept the
                    </span>
                    <a
                        className='link'
                        href='http://www.channel5.com/terms-and-conditions'
                        rel='noopener noreferrer nofollow'
                        target='_blank'
                    >
                        Terms & Conditions
                    </a>
                </div>
                <div className='submit-container'>
                    {isLoading && (
                        <div className='spinner-container'>
                            <div className='spinner'></div>
                        </div>
                    )}
                    <FormButton
                        disabled={!this.validateForm()}
                        buttonType='submit'
                        buttonText='Create Account'
                        buttonId='submit-form'
                    />
                    {responseError && <FormError error={responseError} />}
                </div>
                <div className='extra-content'>
                    <span className='create-account'>Already Have An Account?</span>
                    <input
                        id='login-link'
                        className='link'
                        type='text'
                        value='Sign In Here'
                        readOnly
                        onClick={this.handleSignin}
                    />
                </div>
            </form>
        );
    }
}
