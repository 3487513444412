import clsx from 'clsx';
import React from 'react';
import { version } from '../../../package.json';
import style from './Footer.module.scss';

const Footer: React.FC<{ className?: string }> = ({ className }) => {
    return (
        <div className={clsx(style.root, 'extra-content', className)}>
            <a
                className="link privacy"
                href="https://www.viacomcbsprivacy.com/policy"
                rel="noopener noreferrer nofollow"
                target="_blank"
            >
                Privacy Policy
            </a>
            <div className={style.version}>Version: {version}</div>
        </div>
    );
};

export default Footer;
