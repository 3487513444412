import React from 'react';

import FormButton from 'components/FormButton/FormButton';
import InputField from 'components/InputField/InputField';

import { emailValidation } from 'utils';
import { Screens, INVALID_EMAIL_ERROR } from 'containers/constants';

import './ChangePassword.scss';
import UserService from 'utils/userService';
import FormError from 'components/FormError/FormError';

interface IProps {
    email?: string;
    code?: string;
    handleScreen: Function;
}

interface IState {
    isLoading: boolean;
    showConfirmation: boolean;
    email: string;
    code: string;
    password: string;
    responseError: string;
}

export default class ForgotPassword extends React.Component<IProps, IState> {
    state = {
        isLoading: false,
        showConfirmation: false,
        email: this.props.email || '',
        code: this.props.code || '',
        password: '',
        responseError: '',
    };

    validateForm() {
        return !this.state.isLoading && emailValidation(this.state.email) && this.state.code;
    }

    handleChange = (event: any) => {
        this.setState({ [event.target.id]: event.target.value } as any);
    };

    handleBackButton = () => {
        this.props.handleScreen(Screens.SIGNIN);
    };

    handleSubmit = (event: any) => {
        event.preventDefault();
        const { email, code, password } = this.state;

        if (password.length < 6) {
            this.setState({ responseError: 'Password must be 6 characters long!' });
        } else if (email) {
            this.setState({ isLoading: true }, async () => {
                try {
                    const userService = new UserService();
                    await userService.resetPassword(email.toLowerCase(), code, password);
                    this.setState({ isLoading: false, showConfirmation: true });
                } catch (e) {
                    this.setState({ responseError: (e as any).message, isLoading: false });
                }
            });
        } else {
            this.setState({ responseError: INVALID_EMAIL_ERROR });
        }
    };

    goToSignIn = () => {
        this.props.handleScreen(Screens.SIGNIN);
    };

    goToDeepLink = () => {
        setTimeout(function () {
            window.location.href = 'https://my5.tv';
        }, 25);
        window.location.href = process.env.REACT_APP_DEEPLINK || '';
    };

    getForm = () => {
        const { email, password, responseError } = this.state;
        return (
            <div className="change-password-screen">
                <div className="change-password-header">
                    <h2 className="heading">Change Password</h2>
                    <input className="back-icon" type="text" onClick={this.handleBackButton} />
                </div>
                <div className="form-container">
                    <form onSubmit={this.handleSubmit} className="form-content">
                        <InputField
                            inputType="email"
                            inputValue={email}
                            labelText="Email address: *"
                            inputId="email"
                            handleChange={this.handleChange}
                        />
                        <InputField
                            inputType="password"
                            inputValue={password}
                            labelText="New password: *"
                            inputId="password"
                            showHide={true}
                            handleChange={this.handleChange}
                        />
                        <FormButton
                            disabled={!this.validateForm()}
                            buttonType="submit"
                            buttonText="Submit New Password"
                            buttonId="submit-form"
                        />
                        {responseError && <FormError error={responseError} />}
                    </form>
                </div>
            </div>
        );
    };

    getConfirmation = () => {
        return (
            <div className="confirmation-screen">
                <div className="container">
                    <div className="confirmation-text">
                        <div className="check-icon"></div>
                        <div className="header">Thanks!</div>
                        <div className="note">Your My5 password has now been reset.</div>
                        <div className="note">You can now use this to sign in.</div>
                    </div>
                    <div className="buttons">
                        <button className="continue-button" onClick={this.goToDeepLink}>
                            Continue to My5
                        </button>
                        <button className="activate-button" onClick={this.goToSignIn}>
                            Activate PIN shown on TV
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { showConfirmation } = this.state;
        return showConfirmation ? this.getConfirmation() : this.getForm();
    }
}
