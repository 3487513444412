const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const POSTCODE_INFO_TEXT =
    'We need to know where you are located so we can tailor content and advertising to your area - you can make choices about how we use your data at any point in your app settings page';
const DOB_INFO_TEXT =
    'We will use this to allow us to only serve age-appropriate content and remove the necessity to ask you every time you play age-restricted content';

const PIN_TEXT_NEW_ACCOUNT = 'Thank you, your account has been created. Please provide the code shown on your TV.';
const PIN_TEXT_EXISTING_ACCOUNT = 'Please provide the code shown on your TV.';

const PIN_ERROR_UNAUTHORIZED = 'Your authentication is invalid. Please sign in again.';
const PIN_ERROR_INVALID = 'Your PIN code is invalid. Please try again with a valid PIN code.';

const USER_ERROR_INCORRECT = 'Incorrect email or password.';
const USER_ERROR_EXISTS = 'There is already an account with that email address.';

const INVALID_EMAIL_ERROR = 'Invalid email address. User not found.';

enum Screens {
    SIGNIN = 'signin',
    REGISTER = 'register',
    FORGOT_PASSWORD = 'forgot_password',
    SOCIAL_LOGIN = 'social_login',
    CHANGE_PASSWORD = 'change_password',
    PIN_SCREEN = 'pin_screen',
    RESULT_SCREEN = 'result_screen',
    MY_DATA = 'my_data'
};

enum PinResult {
    OK = 'OK',
    UNAUTHORIZED = 'UNAUTHORIZED',
    INVALID_PIN = 'INVALID_PIN'
};

enum CookiesOptions {
    COOKIES = 'cookies',
    REGISTRATION = 'registration',
};

enum SocialProvider {
    FACEBOOK = 'facebook',
    TWITTER = 'twitter',
    GOOGLE = 'google',
};

export { 
    monthNames, 
    Screens, 
    PinResult, 
    CookiesOptions,
    SocialProvider,
    POSTCODE_INFO_TEXT, 
    DOB_INFO_TEXT, 
    PIN_TEXT_NEW_ACCOUNT,
    PIN_TEXT_EXISTING_ACCOUNT,
    PIN_ERROR_UNAUTHORIZED,
    PIN_ERROR_INVALID,
    USER_ERROR_INCORRECT,
    USER_ERROR_EXISTS,
    INVALID_EMAIL_ERROR
};
