declare global {
    interface Window {
        digitalData: any;
    }
}

const range = (start: number, end: number) => {
    return Array(end - start + 1)
        .fill(0)
        .map((arg: any, idx: number) => start + idx);
};

const postcodeValidation = (postcode: string) => {
    const regex =
        /^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/;

    return postcode.trim().length >= 5 && regex.test(postcode.trim());
};

const onlyLetterValdation = (word: string) => {
    // u2019 is right-single-quotation-mark
    const regex = /^[A-zÀ-ú '\u2019-]+$/i;
    return regex.test(word);
};

const emailValidation = (email: string) => {
    const regex =
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
    return regex.test(email);
};

export const doubleInt = (num: string | number) => ('0' + num).slice(-2);

/**
 * Calculate down to day of birth the accurate age of birth.
 * Calculates if given date of birth is less than an age of a person.
 *
 * Will return boolean.
 *
 * @param age
 * @param birthdate
 */
export const isOverAge = (age: number, birthdate: string): boolean => {
    const dateArray = birthdate.split('/');
    const birthYear = parseInt(dateArray[2], 10);
    const birthdayMonth = parseInt(dateArray[1], 10);
    const birthdayDay = parseInt(dateArray[0], 10);
    const minimumBirthYear = new Date().getFullYear() - age;
    // if birthday year is higher than 18th age THEN return false
    
    if (birthYear === minimumBirthYear) {
        // check if 18th birthday has been completed
        const minimumBirthMonth = new Date().getMonth() + 1;
        if (birthdayMonth > minimumBirthMonth) {
            return false;
        } else if (birthdayMonth < minimumBirthMonth) {
            return true;
        }
        // find if today a person completed given age
        const minimumBirtDay = new Date().getDate();
        return birthdayDay <= minimumBirtDay;
    }
    return birthYear < minimumBirthYear;
};

/**
 * Calculate down to day of birth the accurate age of birth.
 * Calculates if given date of birth is less than an age of a person.
 *
 * Will return boolean.
 *
 * @param dob
 * @param age
 */
export const isOfAge = (dob: string, age: number): boolean => {
    // dates are all converted to date objects
    const my_dob = new Date(dob);
    const today = new Date();
    const max_dob = new Date(today.getFullYear() - age, today.getMonth(), today.getDate());
    return max_dob.getTime() >= my_dob.getTime();
};

const ageBracketCalc = (dob: string): string => {
    const current: Date = new Date();
    const dates: string[] = dob.split('/');
    const year = parseInt(dates[2]),
        month = parseInt(dates[1]),
        day = parseInt(dates[0]);
    const diff =
        current.getFullYear() -
        year +
        (month < current.getMonth() + 1
            ? 0
            : month > current.getMonth() + 1
            ? -1
            : day > current.getDay() + 1
            ? -1
            : 0);

    if (diff < 16) return '<16';
    if (diff < 35) return '16-34';
    if (diff < 45) return '35-44';
    if (diff < 56) return '45-55';
    return '55+';
};

const initTracking = (): void => {
    window.digitalData.page.pageInfo.pageName = process.env.REACT_APP_TRACKING_PAGENAME;
    window.digitalData.page.category.primaryCategory = process.env.REACT_APP_TRACKING_CATEGORY;
    window.digitalData.page.attributes.brandID = process.env.REACT_APP_TRACKING_BRANDID;
    window.digitalData.page.attributes.appName = process.env.REACT_APP_TRACKING_APPNAME;
};

const setTrackingEvent = (eventName: string, primaryCateogry: string, attributes?: any): void => {
    var eventObj: any = {};
    eventObj.eventInfo = {};
    eventObj.category = {};
    eventObj.attributes = {};

    // Define event data layer properties
    eventObj.eventInfo.eventName = eventName;
    eventObj.eventInfo.timeStamp = new Date().getTime();
    eventObj.category.primaryCategory = primaryCateogry;
    if (attributes) eventObj.attributes = attributes;

    // Add event object to data layer
    window.digitalData && window.digitalData.event.push(eventObj);
};

export {
    range,
    postcodeValidation,
    onlyLetterValdation,
    emailValidation,
    initTracking,
    setTrackingEvent,
    ageBracketCalc,
};
